<template>

    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <div class="card">
                <div class="card-header bg-white">
                <div class="d-flex align-items-center">
                    <h5 class="card-title font-weight-semibold">Aktfitas Antrean</h5>
                </div>
                </div>

                <ul class="nav nav-tabs nav-justified nav-tabs-highlight mb-0">
                    <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" data-toggle="tab" 
                    :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Semua Data</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" data-toggle="tab" 
                    :class="activeTab == 2 ? 'nav-link active' : 'nav-link'">Pencarian</a></li>
                </ul>
                
                <div class="tab-content">
                    <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'" id="actTaskRsh">
                        <div class="card-header">
                        <div class="row">
                            <div class="col-md-3">
                                <v-select placeholder="Pilih Type" v-model="filter.typeFilter"
                                    @input="changeTypeDashboard($event)"
                                    :options="Config.mr.typeFilterBelumLayan" label="text" :clearable="true"
                                    :reduce="v=>v.value">
                                </v-select>
                            </div>
                            <div class="col-md-3" v-if="filter.typeFilter == 'AP'">
                                <div class="input-group">
                                    <div class="input-group mb-3">
                                        <datepicker
                                        input-class="form-control transparent"
                                        placeholder="Tanggal" class="my-datepicker"
                                        calendar-class="my-datepicker_calendar"
                                        v-model="filter.date">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3"  v-if="filter.typeFilter == 'AK'">
                                <b-form-input placeholder="Nomor Registrasi" v-model="filter.noreg" type="text" class="form-control" />
                            </div>
                            <div class="col-auto" v-if="filter.typeFilter">
                                <button @click="doSearch()" class="btn btn-labeled btn-labeled-left btn-primary">
                                    <b><i class="icon-search4"></i></b>
                                    <span>Cari</span>
                                </button>
                                <button @click="doReset" class="btn btn-outline-warning ml-1">Reset</button>
                            </div>
                        </div>
                        </div>
                        <div class="table-responsive">
                        <table
                            class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                            <thead>
                            <tr>
                                <th width="120">No. Registrasi / <br/> No. RM / <br/> NIK</th>
                                <th width="120">Poli / <br/> Dokter / <br/> Jam Praktek</th>
                                <th width="120">No. Kartu BPJS / <br/> No. HP / <br/> No. Referensi</th>
                                <th width="120">Jenis Kunjungan</th>
                                <th width="120">No. Antrean / <br/> Estimasi Dilayani</th>
                                <th width="120">Hari</th>
                                <th width="120">Peserta BPJS?</th>
                                <th width="120">Sumber Data</th>
                                <th width="120">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(v,k) in (antreanBelumDilayani||[])" :key="k">
                                    
                                    <td>{{v.kodebooking||"-"}} / {{v.norekammedis||"-"}} / {{v.nik||"-"}}</td>
                                    <td>{{v.poli||"-"}} / {{v.dokter||"-"}} / {{v.jampraktek||"-"}}</td>
                                    <td>{{v.nokapst||"-"}} / {{v.nohp||"-"}} / {{v.nomorreferensi||"-"}}</td>
                                    <td>
                                        <span v-if="v.jeniskunjungan == 1">Rujukan FKTP</span>
                                        <span v-else-if="v.jeniskunjungan == 2">Rujukan Internal</span>
                                        <span v-else-if="v.jeniskunjungan == 3">Kontrol</span>
                                        <span v-else>Rujukan Antar RS</span>
                                    </td>
                                    <td>{{v.noantrean}} / {{v.estimasi_layan}} WIB</td>

                                    <td>{{v.tanggal}}</td>
                                    <td>{{v.ispeserta ? "Ya" : "Tidak"}}</td>
                                    <td>{{v.sumberdata}}</td>
                                    <td>{{v.status}}</td>
                                </tr>
                                <tr v-if="!(antreanBelumDilayani||[]).length">
                                    <td colspan="9" class="text-center">Tidak ada data antrean</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'" id="actTaskBpjs">
                        <div class="card-body">
                        <div class="form-row">
                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Tanggal</label>
                                <div class="input-group">
                                    <div class="input-group mb-3">
                                        <datepicker
                                        input-class="form-control transparent"
                                        placeholder="Tanggal" class="my-datepicker"
                                        calendar-class="my-datepicker_calendar"
                                        v-model="filter.date">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Poli</label>
                                            
                                <v-select placeholder="Pilih Poli" @input="doFill();filter.dokter=null;filter.jampraktek=null" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" value="val" :reduce="v=>v.val"></v-select>
                            </div>
                            </div>
                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Dokter</label>
                                <v-select @input="doFill();filter.jampraktek=null" placeholder=" -- Pilih Dokter -- " v-model="filter.dokter"
                                    :options="mDokter" label="text" value="val" :clearable="true"
                                    :reduce="v=>v.val">
                                </v-select>
                            </div>
                            </div>
                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="">Jam Praktek</label>
                                <v-select @input="doFill()" placeholder=" -- Pilih Jam Praktek -- " v-model="filter.jampraktek"
                                    :options="jampraktek" label="text" :clearable="true"
                                    :reduce="v=>v.value">
                                </v-select>

                            </div>
                            </div>
                            <div class="col-12">
                            <div class="text-right">
                                <button @click="doReset" class="btn btn-outline-warning ml-1">Reset</button>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="table-responsive">
                        <table
                            class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                            <thead>
                            <tr>
                                <th width="120">No. Registrasi / <br/> No. RM / <br/> NIK</th>
                                <th width="120">Poli / <br/> Dokter / <br/> Jam Praktek</th>
                                <th width="120">No. Kartu BPJS / <br/> No. HP / <br/> No. Referensi</th>
                                <th width="120">Jenis Kunjungan</th>
                                <th width="120">No. Antrean / <br/> Estimasi Dilayani</th>
                                <th width="120">Hari / <br/> Jam</th>
                                <th width="120">Peserta BPJS?</th>
                                <th width="120">Sumber Data</th>
                                <th width="120">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (antreanByParams||[])" :key="k">
                                
                                <td>{{v.kodebooking||"-"}} / {{v.norekammedis||"-"}} / {{v.nik||"-"}}</td>
                                <td>{{v.poli||"-"}} / {{v.dokter||"-"}} / {{v.jampraktek||"-"}}</td>
                                <td>{{v.nokapst||"-"}} / {{v.nohp||"-"}} / {{v.nomorreferensi||"-"}}</td>
                                <td>
                                    <span v-if="v.jeniskunjungan == 1">Rujukan FKTP</span>
                                    <span v-else-if="v.jeniskunjungan == 2">Rujukan Internal</span>
                                    <span v-else-if="v.jeniskunjungan == 3">Kontrol</span>
                                    <span v-else>Rujukan Antar RS</span>
                                </td>
                                <td>{{v.noantrean}} / {{v.estimasi_layan}} WIB</td>

                                <td>{{v.tanggal}}</td>
                                <td>{{v.ispeserta ? "Ya" : "Tidak"}}</td>
                                <td>{{v.sumberdata}}</td>
                                <td>{{v.status}}</td>
                            </tr>
                            <tr v-if="!(antreanByParams||[]).length">
                                <td colspan="9" class="text-center"
                                    v-if="!filter.poli || !filter.dokter || !filter.jampraktek || !filter.date"
                                >
                                    Data akan muncul setelah parameter disubmit
                                </td>
                                <td colspan="9" class="text-center"
                                    v-else
                                >
                                    Tidak ada Data
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')

export default {
  extends: GlobalVue,
  data() {
    return {
        activeTab: 1,
        antreanBelumDilayani : [],
        antreanByParams : [],

        mPoli : [],
        mDokter : [],
        jampraktek : [],
    }      
  },
    components: {
      Datepicker
    },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted() {
      this.filter.poli = null
      this.filter.dokter = null
      this.filter.jampraktek = null
      this.filter.date = moment().format('YYYY-MM-DD')
      this.filter.typeFilter = "AB"

      this.apiGet()
  },
  methods: {
        changeTypeDashboard(e){
            this.filter = {}
            this.filter.typeFilter = e
            if(e == 'AP'){
                this.filter.date = moment().format('YYYY-MM-DD')
            }
            this.doFill()
        },
        changeTab(tab){
            this.filter = {}
            this.activeTab = tab
            this.filter.typeFilter = "AB"
            
            if(tab == 2){
                this.filter.date = moment().format('YYYY-MM-DD')                
                if(this.filter.date){
                    this.filter.date = moment(this.filter.date, "YYYY-MM-DD").format('YYYY-MM-DD')
                }
            }
            this.filter.activeTab = tab
            this.doFill()
        },
      
        doSearch(){
            this.apiGet()
        },  

        doFill(){
            if(this.filter.typeFilter == 'AP'){
                if(this.filter.date){
                    this.filter.date = moment(this.filter.date, "YYYY-MM-DD").format('YYYY-MM-DD')
                }
            }
            this.doFilter()
        },

        doFilter(urlOnly=false){
            if(this.exportType && this.filter){
                let f = Object.values(this.filter).filter(attr=>attr)
                if(f.length>=1){ 
                this.exportType = 'filtered'
                }else{
                this.exportType = 'all'
                }
            }
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, {},_.clone(this.filter), {page:1})
            }
            
            if(urlOnly) return url
            this.pageNo=1
            this.$router.push(url).catch(()=>{})
        },
  },
  watch:{
      
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }

}
</script>
